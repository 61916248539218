<!-- 个人中心 -->
<template>
  <div style="height:100%">
    <el-button class="returnBtn" @click="returnBtn">返回</el-button>
    <el-button class="returnBtn" type="primary" @click="editorBtn">编辑</el-button>
    <el-card class="span-card">
      <div class="span-div">
        <p>个人中心</p>
        <span class="span-label">姓名：{{personalDetails.fullName || '暂无信息'}}</span>
        <span class="span-label">性别：{{personalDetails.gender || '暂无信息'}}</span>
        <span class="span-label">邮箱：{{personalDetails.email || '暂无信息'}}</span>
        <span class="span-label">手机：{{personalDetails.number || '暂无信息'}}</span>
        <span class="span-label">QQ：{{personalDetails.qq || '暂无信息'}}</span>
        <span class="span-label">微信：{{personalDetails.wx || '暂无信息'}}</span>
        <span class="span-label">地址：{{personalDetails.address || '暂无信息'}}</span>
        <span class="span-label">最后登录时间：{{personalDetails.finallyLoginTime || '暂无信息'}}</span>
        <span class="span-label">最后登录IP：{{personalDetails.finallyLoginIp || '暂无信息'}}</span>
      </div>
    </el-card>
    <el-dialog title="编辑个人中心" :visible.sync="editVisible" width="50%" :before-close="handleClose">
      <el-form
        :model="ruleFormEdit"
        :rules="rulesEdit"
        ref="ruleFormEdit"
        label-width="130px"
        class="demo-ruleForm"
      >
        <el-form-item label="姓名：">
          <el-input v-model="ruleFormEdit.fullName" size="mini" disabled></el-input>
        </el-form-item>
        <el-form-item label="性别：" prop="region">
          <el-select
            v-model="ruleFormEdit.region"
            placeholder="请选择性别"
            style="width:100%"
            size="mini"
          >
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="邮箱：" prop="email">
          <el-input v-model="ruleFormEdit.email" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="电话：">
          <el-input v-model="ruleFormEdit.number" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="QQ：" prop="qqName">
          <el-input v-model="ruleFormEdit.qqName" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="微信：" prop="wxName">
          <el-input v-model="ruleFormEdit.wxName" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="地址：" prop="address">
          <el-input v-model="ruleFormEdit.address" size="mini"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="oKEdit('ruleFormEdit')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { userPerson } from '../../api/api'
import { userUpdate } from '../../api/api'
export default {
  data () {
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
      if (!value) {
        return callback(new Error('电话号码不能为空'))
      }
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('电话号码格式不正确'))
          }
        }
      }, 100)
    };
    var validateEmail = (rule, value, callback) => {
      const phoneReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/
      if (!value) {
        // return callback(new Error('邮箱不能为空'))
      }
      setTimeout(() => {
        if (phoneReg.test(value)) {
          callback()
        } else {
          callback(new Error('邮箱格式不正确'))
        }
      }, 100)
    };
    return {
      appIdUser: null,//userid
      personalDetails: {},//个人中心的信息
      editVisible: false,//是否显示编辑弹框
      personalEditor: false,//(该字段没用到)
      ruleFormEdit: {//表单数据
        fullName: null,//名称
        qqName: null,//qq
        wxName: null,//微信
        number: null,//电话
        address: null,//地址
        schoolTime: null,//开学时间
        grade: null,//年级
        email: null,//邮箱
        finallyLoginTime: null,//最后的登录时间
        finallyLoginIp: null,//最后登录ip
        region: null,//性别
      },
      rulesEdit: {//校验规则
        fullName: [
          { required: false, message: '请输入活动名称', trigger: 'blur' },
          { min: 2, max: 6, message: '长度在 2 到 6 个字符', trigger: 'blur' }
        ],
        number: [
          { required: false, trigger: 'blur', validator: checkPhone }
        ],
        email: [{ required: false, message: '邮箱不能为空', trigger: 'blur' }],
        schoolTime: [
          { type: 'date', required: false, message: '请选择日期', trigger: 'change' }
        ],
        region: [
          { required: false, message: '请选择性别', trigger: 'change' }
        ],
      },
    }
  },

  components: {},
  mounted () {
    this.load()
    this.analysis()
  },
  methods: {
    // 解析appIds
    analysis () {
      var urlAppId = window.location.href.split('=')[2].split('#')[0]
      let appIds = JSON.parse(localStorage.getItem('appIds'))
      if (appIds) {
        let target = appIds.find(item => { return item.appId === urlAppId })
        this.appIdUser = target.userId
      }
    },
    oKEdit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let param = {
            "id": Number(this.appIdUser),
            "fullName": this.ruleFormEdit.fullName,//名字
            "qq": this.ruleFormEdit.qqName,//qq
            "wx": this.ruleFormEdit.wxName,//wx
            "number": this.ruleFormEdit.number,//电话
            "address": this.ruleFormEdit.address,//地址
            "schoolTime": this.ruleFormEdit.schoolTime,//开学时间
            'sex': Number(this.ruleFormEdit.region),
            'email': this.ruleFormEdit.email
          }
          userUpdate(param).then(res => {
            if (res.data) {
              this.editVisible = false
              this.ruleFormEdit = {
                fullName: null,
                qqName: null,
                wxName: null,
                number: null,
                address: null,
                schoolTime: null,
                grade: null,
                email: null,
                finallyLoginTime: null,
                finallyLoginIp: null,
                region: null
              }
              this.$message({
                message: '编辑成功！',
                type: 'success'
              });
              this.load()
            }
          })
        } else {
          return false;
        }
      });
    },
    load () {
      userPerson().then(res => {
        if (res.data) {
          if (res.data.sex == '1') {
            res.data.gender = '男'
          } else {
            res.data.gender = '女'
          }
          if (res.data.schoolTime) {
            let a = res.data.schoolTime.split('T')[0].split('-')
            let b = Number(a[2]) + 1
            b = b + ''
            a.push(b)
            a.splice(2, 1)
            a = a.join('-')
            res.data.time = a
          }
          this.personalDetails = res.data
        }
      })
    },
    completeBtn () {
      this.personalEditor = false
    },
    // 打开编辑个人中心
    editorBtn () {
      this.ruleFormEdit.fullName = this.personalDetails.fullName
      this.ruleFormEdit.region = this.personalDetails.sex
      this.ruleFormEdit.email = this.personalDetails.email
      this.ruleFormEdit.number = this.personalDetails.number
      this.ruleFormEdit.qqName = this.personalDetails.qq
      this.ruleFormEdit.wxName = this.personalDetails.wx
      this.ruleFormEdit.address = this.personalDetails.address
      this.editVisible = true
    },
    // 返回
    returnBtn () {
      this.$router.go(-1)
    },
    // 弹框二次确认
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    // 时间转换
    gettime (data) {
      let value = data.getFullYear() + '-' +
        this.checkTime(data.getMonth() + 1) + '-' +
        this.checkTime(data.getDate()) + ' ' +
        this.checkTime(data.getHours()) + ':' +
        this.checkTime(data.getMinutes()) + ':' +
        this.checkTime(data.getSeconds());
      return value
    },
    checkTime (i) {
      if (i < 10) {
        i = "0" + i
      }
      return i;
    },

  }
}

</script>
<style lang='less' scoped>
@import '../../style/mouse.less';
.returnBtn {
  width: 100px;
  margin-bottom: 10px;
}
.span-card {
  height: 100%-10px;
  /deep/.el-card__body {
    height: 100%;
  }
  .span-div {
    width: 100%;
    // min-width: 1024px;
    .span-label {
      width: 50%;
      display: inline-block;
      margin: 30px 0;
      padding: 0 0 0 50px;
    }
  }
}
.el-dialog {
  .el-form-item {
    padding-left: 10px;
    width: 50%;
    display: inline-block;
  }
}
</style>
